import {requestGet} from "../../../services/request";
import * as S from '../style/NavStyle';
import {getCookie, setCookie} from "../../../services/loginCookie";


function Right() {

    const cookieSetting = (autoLoginCheck)=>{
        if(autoLoginCheck === 'y'){
            setCookie('wid_ys',1004,{
                path:'/',
                // maxAge: 60 * 2

                // 아래는 라이브서버에서
                maxAge: 60 * 60 * 24 * 15
            })
        }else{
            setCookie('wid_ys',1004,{
                path:'/',
                // maxAge: 60 * 2

                // 아래는 라이브서버에서
                maxAge: 60 * 60 * 24
            })
        }
    }




    const handleLogout = async () => {

        try{
            const res = await requestGet('/user/logout');

            if(res.code === 0){
                // removeCookie('wid_ys')

                if (localStorage.getItem('autoLogin')) {
                    cookieSetting(localStorage.getItem('autoLogin'))
                    localStorage.removeItem('autoLogin');
                    localStorage.removeItem('whether');
                } else {
                    cookieSetting(sessionStorage.getItem('autoLogin'))
                    sessionStorage.removeItem('autoLogin');
                    sessionStorage.removeItem('whether');
                }
                window.location.href = '/login';

            }


        }catch (e) {


            console.log(e)
        }


    }



    return (
        <S.ButtonContainer tabIndex={-1}>
            {getCookie('wid_ys') !== 0
                ?
                <>
                    <S.GoToSignUp to={'/signup'} title={'회원가입페이지로 이동'}>회원가입</S.GoToSignUp>
                    <S.GoToLogin to={'/login'} title={'로그인페이지로 이동'}>로그인</S.GoToLogin>
                </>
                :
                <>
                    <S.GoToSignUp to={'/mypage'} title={'마이페이지페이지로 이동'}>마이페이지</S.GoToSignUp>
                    <S.LogoutBtn onClick={()=>handleLogout()} title={'로그아웃'}>로그아웃</S.LogoutBtn>
                </>
            }


        </S.ButtonContainer>
    );
}

export default Right;
