import styled from 'styled-components'
import {motion} from "framer-motion";
import {ReactComponent as XmarkIcon} from "../../Svg/X_button.svg";
import {ReactComponent as DropDown} from "../../Svg/dropdown.svg";




export const Wrapper = styled.div`
  position: fixed;
  ${({theme}) => theme.allOfCenter};
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 996;
  background-color: ${({theme}) => theme.colors.gray7};
  opacity: 0.74;
  

`
export const Container = styled(motion.nav)`
  width: 28.8rem;
  height: 100%;
  background: white;
  overflow: scroll;
  z-index: 997;
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  transform: translate(-50%, -50%);

  
  ::-webkit-scrollbar{
    display: none;
  }
`

export const Box = styled.div`
  width: 100%;
  padding: 0 1.6rem;

`

export const Box2 = styled.div`
  width: 100%;

`

export const DivisionLine = styled.hr`
  width: 100%;
  height: 0.8rem;
  background: ${({theme}) => theme.colors.gray};
  border: none;
  margin: 2.4rem 0 0.8rem 0;
`

export const Title = styled.h1`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1.6rem;
  font-size: 2rem;
  font-weight: 500;

`

export const Title2 = styled.div`
  display: flex;
  margin-bottom: 1.6rem;


  > h1 {
    font-size: 2rem;

    :nth-child(1) {
      font-weight: bold;
      margin-right: 0.4rem;
    }
    :nth-child(2) {
      font-weight: 500;
    }
  }
`

export const XBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const XIcon = styled(XmarkIcon)`
  width: 3.2rem;
  height: 3.2rem;
  margin: 4.4rem 0 4rem 0;
  color: #bbbbbb;
  cursor: pointer;
`

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const LoginBtn = styled.button`
  width: 12.4rem;
  height: 5.2rem;
  padding: 1.4rem 0;
  ${({theme}) => theme.button.type1};
  ${({theme}) => theme.fontStyle.mainText};
  border-radius: 0.4rem;
  font-weight: 500;
`

export const SignUpBtn = styled(LoginBtn)`
  border: 0.05rem solid ${({theme})=>theme.colors.main};
  ${({theme}) => theme.button.type3};

`


export const MainMenuBox = styled.ul`
  border-bottom: 1px solid ${({theme}) => theme.colors.gray2};

  :last-child {
    border: none;
  }
`

export const MainMenu = styled.li`
  ${({theme}) => theme.fontStyle.mainText};
  width: 100%;
  height: 5.2rem;
  //margin: 3rem 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem;
  font-weight: 500;

`

export const SubMenuBox = styled(motion.ul)`
  width: 100%;
  padding: 0 3.2rem;
position:relative;
  top:0;
  left:0;
`

export const SubMenu = styled.li`
  width: 100%;
  height: 5.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 1.8rem;
  

`

export const DropDownIcon = styled(DropDown)`
  width: 2.4rem;
  height: 2.4rem;
  rotate: ${props=> props.$rotate ? '-180deg' : '0' };
  transition:0.3s all;
`


export const LogoutBtn = styled(LoginBtn)`
  width: 100%;
    ${({theme}) => theme.button.type1};
`