import {useLocation, useNavigate} from "react-router";
import SideBar from "../SideBar/SideBar";
import {useState} from "react";
import * as S from './style/UnderNavStyle';

function UnderNav(props) {

    const navigate = useNavigate();

    const [openBar, setOpenBar] = useState(false);


    const handleSideBar = () => {
        setOpenBar(false)
    }
    const underBarUrl = ['goodsdetail','chatbox']

    const location = useLocation();

    return (
        <>
            {
                openBar &&
                <SideBar
                    handleSideBar={handleSideBar}
                />
            }
            <S.Wrapper $display={underBarUrl.includes(location.pathname.split('/')[1])}>
                <S.Icons>

                    <S.Icon1 onClick={() => {
                        setOpenBar(!openBar)
                    }}/>


                    <S.Icon2
                        onClick={() => {
                        navigate('/')
                    }}
                             $activate={window.location.pathname === '/'}
                    />


                    <S.Icon3 onClick={() => {
                        navigate('/useddeal?menu=goodslist')
                    }}
                             $activate={window.location.pathname.includes('/useddeal')}

                    />


                    <S.Icon4 onClick={() => {
                        navigate('/mypage')
                    }}
                             $activate={window.location.pathname.includes('/mypage')}

                    />
                </S.Icons>

            </S.Wrapper>
        </>
    );
}

export default UnderNav;
