import {client} from './client'
import {refresh} from "../pages/Login/refresh";
import {removeCookie, setCookie} from "./loginCookie";



const cookieSetting = (autoLoginCheck,code)=>{
    if(autoLoginCheck === 'y'){
        setCookie('wid_ys',code,{
            path:'/',
            // maxAge: 60 * 2

            // 아래는 라이브서버에서
            maxAge: 60 * 60 * 24 * 15
        })
    }else{
        setCookie('wid_ys',code,{
            path:'/',
            // maxAge: 60 * 2

            // 아래는 라이브서버에서
            maxAge: 60 * 60 * 24
        })
    }
}

export const requestPatch = async (url, updateData, config) => {
    try {
        const response = await client.patch(url, updateData, config)
        return response.data
    } catch (error) {
        if (error.response.data) {
            return error.response.data
        } else {
            throw error
        }
    }
}



export const requestDelete = async (url, config) => {
    try {
        const response = await client.delete(url, config)
        return response.data
    } catch (error) {
        if (error.response.data) {
            return error.response.data
        } else {
            throw error
        }
    }
}



export const requestFile = async (url, formData, config = {}) => {
    try {
        const response = await client.post(url, formData, {
            ...config,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return response.data
    } catch (error) {
        if (error.response.data) {
            return error.response.data
        } else {
            throw error
        }
    }
}



export const requestGet = async (url, config) => {

    try {
        const response = await client.get(url, config)
        return response.data;

    } catch (error) {
        // console.log(error)
        // console.log(error?.response?.data?.code)


        if(error?.response?.data?.code === 1002){
            // removeCookie('wid_ys')
            if (localStorage.getItem('autoLogin')) {
                cookieSetting(localStorage.getItem('autoLogin'),1002)
                localStorage.removeItem('autoLogin');

            } else {
                cookieSetting(sessionStorage.getItem('autoLogin'),1002)
                sessionStorage.removeItem('autoLogin');
            }
            window.location.href = "/login";
        }
        else if(error?.response?.data?.code === 1003){
            if (localStorage.getItem('autoLogin')) {
                cookieSetting(localStorage.getItem('autoLogin'),1003)
                localStorage.removeItem('autoLogin');
            } else {
                cookieSetting(sessionStorage.getItem('autoLogin'),1003)
                sessionStorage.removeItem('autoLogin');
            }
            window.location.href = "/login";
        }
        else if(error?.response?.data?.code === 1013){
            return await refresh() ;
        }
        else if(error?.response?.data?.code === 1014){
            if (localStorage.getItem('autoLogin')) {
                cookieSetting(localStorage.getItem('autoLogin'),1004)
                localStorage.removeItem('autoLogin');
            } else {
                cookieSetting(localStorage.getItem('autoLogin'),1004)
                sessionStorage.removeItem('autoLogin');
            }
            window.location.href = "/login";
        }
        else if (error?.response?.data) {
            return error?.response?.data
        }
        else {
            throw error
        }
    }
}



export const requestPut = async (url, body, config) => {
    try {

        const response = await client.put(url, body, config)
        return response.data
    } catch (error) {
        if (error.response.data) {
            return error.response.data
        } else {
            throw error
        }
    }
}



export const requestPost = async (url, body, config) => {
    try {
        const response = await client.post(url, body, config)
        return response.data
    } catch (error) {
        // console.log(error)
        if(error?.response?.data?.code === 1002){
            // removeCookie('wid_ys')

            if (localStorage.getItem('autoLogin')) {
                cookieSetting(localStorage.getItem('autoLogin'),1002)
                localStorage.removeItem('autoLogin');

            } else {
                cookieSetting(sessionStorage.getItem('autoLogin'),1002)
                sessionStorage.removeItem('autoLogin');
            }
            window.location.href = "/login";
        }
        else if(error?.response?.data?.code === 1003){

            // removeCookie('wid_ys')
            if (localStorage.getItem('autoLogin')) {
                cookieSetting(localStorage.getItem('autoLogin'),1003)
                localStorage.removeItem('autoLogin');

            } else {
                cookieSetting(sessionStorage.getItem('autoLogin'),1003)
                sessionStorage.removeItem('autoLogin');

            }
            window.location.href = "/login";
        }
        else if(error?.response?.data?.code === 1013){
            return await refresh()
        }

        else if(error?.response?.data?.code === 1014){
            if (localStorage.getItem('autoLogin')) {
                cookieSetting(localStorage.getItem('autoLogin'),1004)
                localStorage.removeItem('autoLogin');
            } else {
                cookieSetting(localStorage.getItem('autoLogin'),1004)
                sessionStorage.removeItem('autoLogin');
            }
            window.location.href = "/login";
        }
        else if (error?.response?.data) {
            return error?.response?.data
        }
        else {
            throw error
        }
    }
}
