export const menuAnimate = {
    enter: {
        height:'13rem',
        opacity: 1,
        transition: {
            duration: 0.2
        },
    },
    exit: {
        height:0,
        opacity: 0,

    }
};

export const fourthMenuAnimate = {
    enter: {
        height:'10.2rem',
        opacity: 1,
        transition: {
            duration: 0.2
        },
    },
    exit: {
        height:0,
        opacity: 0,

    }
};
