import {useEffect, useRef} from "react";
import {getCookie} from "./loginCookie";

export const useInterval = (callback, delay) => {
    const savedCallback = useRef(null);

    useEffect(() => {
        savedCallback.current = callback;
    },[callback]);

    useEffect(() => {
        const tick = () => {
            savedCallback.current();
        }

        const timerId = setInterval(tick, delay);
        return () => clearInterval(timerId);
    }, [getCookie('wid_ys'),delay]);
}