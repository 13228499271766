import styled from 'styled-components'
import {ReactComponent as Check} from "../Svg/complete.svg";



export const Wrapper = styled.div`
  position: fixed;
  ${({theme}) => theme.allOfCenter};
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.74);
}

`

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  ${({theme}) => theme.allOfCenter};
  flex-direction: column;
  width: 53.2rem;
  z-index: 999;
  background: white;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
    padding: 4rem 2.4rem 2.4rem 2.4rem;
  
  @media screen and (max-width: 480px){
    width: 90%;

  }

`

export const CheckIcon = styled(Check)`
width:4.8rem;
  height: 4.8rem;
  margin-bottom: 0.8rem;
  
  path{
    stroke:${({theme})=>theme.colors.main};
  }
`

export const Title = styled.h1`
  text-align: center;
  line-height: 2.4rem;
  white-space: pre-wrap;
  font-weight:500;
    font-size: 1.8rem;
color:black;
`

export const SubTitle = styled.h2`
  font-size: 1.6rem;
  color: ${({theme})=>theme.colors.gray7};
  text-align: center;
  white-space: pre-wrap;
  line-height: 2.4rem;
  margin-top: 2rem;
`

export const Caution = styled.h3`
  font-size: 1.6rem;
  color: ${({theme})=>theme.colors.error};
  text-align: center;
  white-space: pre-wrap;
  line-height: 2.4rem;
  margin-top: 2rem;
`


export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 3.2rem 0 0 0;

  > button {
    margin-right: 1rem;

    :last-child {
      margin-right: 0;
    }
  }
`
export const Confirm = styled.button`
  width: 13.6rem;
  height: 5.2rem;
  ${({theme}) => theme.button.type1};
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 500;

  @media screen and (max-width: 480px) {
    width: 48%;
    height: 5rem;
    ${({theme}) => theme.button.type1};
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.2rem;
    padding:0;
  }

`
export const Cancel = styled(Confirm)`
  ${({theme}) => theme.button.type3};
`
