import {useEffect, useState} from "react";
import * as S from './style/NavStyle';
import {useNavigate} from "react-router";
import Left from "./components/Left";
import Right from "./components/Right";
import {useMediaQuery} from "react-responsive";
import {requestGet} from "../../services/request";
import {useInterval} from "../../services/useInterval";
import {getCookie} from "../../services/loginCookie";


function Nav() {

    const redirectWWWUrl = () => {
        const href = window.location.href;

        const wwwUrl = href.split("//")[1].split(".");

        if (wwwUrl[0] == 'www') {

            window.location.href = href.replace('www.', '');
        }
    }
    const [numberOfMessage, setNumberOfMessage] = useState(0);


    const getMessageInfo = async () => {
        const res = await requestGet('/usedDeal/message/unreadmessage')

        if (res?.code === 0) {
            setNumberOfMessage(res.data.messageCnt)

        }
    }

    // useEffect(() => {
    //     try {
    //         if (getCookie('wid_ys')) {
    //             getMessageInfo()
    //         }
    //     } catch (e) {

    //         console.log(e)
    //     }
    //
    // }, [numberOfMessage])


    useEffect(() => {
        redirectWWWUrl();
    }, [window.location.href]);

    const navigate = useNavigate()

    const isMobile = useMediaQuery({query: '(min-width:0) and (max-width: 480px)'});

    //1분 1000*60
    useInterval(() => {
        if (getCookie('wid_ys') === 0) {
            try{
                getMessageInfo();

            }catch (e) {

                console.log(e)
            }
        }
    }, 600000);


    return (
        <>
            {isMobile ?
                <S.Wrapper>
                    <S.Icons>
                        <S.LogoIcon onClick={() => navigate('/')}/>
                        <S.MessageAlarm onClick={() => navigate('/useddeal?menu=messagebox')}
                        margin={numberOfMessage !== 0}
                        >
                            <S.MessageIcon/>

                            {numberOfMessage !== 0 &&
                                <>
                                    <S.Numbering>{numberOfMessage}+</S.Numbering>
                                    <S.ToolTip>안 읽은 쪽지가 <span>{numberOfMessage}</span>건 있습니다.</S.ToolTip>
                                </>
                            }

                        </S.MessageAlarm>
                    </S.Icons>
                </S.Wrapper>
                :
                <S.Wrapper>
                    <S.Container>
                        <Left numberOfMessage={numberOfMessage}/>
                        <Right/>
                    </S.Container>

                </S.Wrapper>
            }
        </>
    );
}

export default Nav;

