import {useEffect, useState} from 'react';
import {fourthMenuAnimate, menuAnimate} from "../../../styles/animate";
import {navMenu} from "../../../models/NavData";
import * as S from '../style/NavStyle';
import {Link} from "react-router-dom";

function Left({numberOfMessage}) {


    const redirectWWWUrl = () => {
        const href = window.location.href;
        const wwwUrl = href.split("//")[1].split(".");
        if (wwwUrl[0] == 'www') {
            window.location.href = href.replace('www.', '');
        }
    }


    useEffect(() => {
        redirectWWWUrl();
    }, [window.location.href]);


    const [showFirstMenu, setShowFirstMenu] = useState(false);
    const [showSecondMenu, setShowSecondMenu] = useState(false);
    const [showFifthMenu, setShowFifthMenu] = useState(false);



    return (
        <S.LeftBox>
            <S.MainLink to={'/'} title='메인페이지로 이동'>
                <h1>휠체어코리아닷컴</h1>
                <S.LogoIcon aria-hidden={true} focusable={false}/>
            </S.MainLink>

            <S.MenuContainer>
                <li onKeyUp={(e)=>{
                    if (e.key === "Tab") {
                        e.preventDefault();
                        setShowFirstMenu(true)
                    }
                }}>
                    <S.KindOfMenu onMouseOver={() => setShowFirstMenu(true)}
                                  onMouseLeave={() => setShowFirstMenu(false)}
                                  >
                        <S.FirstMenu
                            onMouseOver={() => setShowFirstMenu(true)}
                            onMouseLeave={() => setShowFirstMenu(false)}
                            activate={showFirstMenu}
                        >
                            <Link to={'/insurancedetail/30'} title='휠체어보험 상세설명 페이지로 이동'>
                                휠체어보험
                            </Link>
                        </S.FirstMenu>

                        {showFirstMenu && (
                            <li>
                                <S.FirstMenuBox
                                    onMouseLeave={() => {
                                        setShowFirstMenu(false)
                                    }}
                                    initial='exit'
                                    animate={showFirstMenu ? 'enter' : 'exit'}
                                    variants={fourthMenuAnimate}>
                                    {navMenu["first"].map((menu, i) => {
                                        return (
                                            <S.FirstSubMenu key={i}
                                                            onClick={() => setShowFirstMenu(false)}>
                                                <Link to={menu[Object.keys(menu)]}
                                                   title={`${Object.keys(menu)} 페이지로 이동`}
                                                >
                                                    {Object.keys(menu)}
                                                </Link>
                                            </S.FirstSubMenu>
                                        );
                                    })}
                                </S.FirstMenuBox>
                            </li>
                        )}
                    </S.KindOfMenu>
                </li>

                {/*<li onKeyUp={(e)=>{*/}
                {/*    if (e.key === "Tab") {*/}
                {/*        e.preventDefault();*/}
                {/*        setShowFirstMenu(false)*/}
                {/*        setShowSecondMenu(true)*/}
                {/*    }}}>*/}
                {/*    <S.KindOfMenu onMouseOver={() => setShowSecondMenu(true)}*/}
                {/*                  onMouseLeave={() => setShowSecondMenu(false)}>*/}

                {/*        <S.SecondMenu*/}
                {/*            onMouseOver={() => setShowSecondMenu(true)}*/}
                {/*            onMouseLeave={() => setShowSecondMenu(false)}*/}
                {/*            activate={showSecondMenu}*/}
                {/*        >*/}
                {/*            <Link to={'/signuptravelinsurance?region=domestic&menu=signup'} title='moveToInsuranceAdmin'>*/}
                {/*                여행자보험*/}
                {/*            </Link>*/}
                {/*        </S.SecondMenu>*/}

                {/*        {showSecondMenu && (*/}
                {/*            <li>*/}
                {/*                <S.SecondMenuBox*/}
                {/*                    onMouseLeave={() => {*/}
                {/*                        setShowSecondMenu(false)*/}
                {/*                    }}*/}
                {/*                    initial='exit'*/}
                {/*                    animate={showSecondMenu ? 'enter' : 'exit'}*/}
                {/*                    variants={menuAnimate}*/}
                {/*                >*/}
                {/*                    {navMenu["second"].map((menu, i) => {*/}
                {/*                        return (*/}
                {/*                            <S.SecondSubMenu key={i}*/}
                {/*                                             onClick={() => setShowSecondMenu(false)}>*/}
                {/*                                <Link to={menu[Object.keys(menu)]}*/}
                {/*                                      title={`${Object.keys(menu)} 페이지로 이동`}>*/}
                {/*                                    {Object.keys(menu)}*/}
                {/*                                </Link>*/}
                {/*                            </S.SecondSubMenu>*/}
                {/*                        );*/}
                {/*                    })}*/}
                {/*                </S.SecondMenuBox>*/}
                {/*            </li>*/}
                {/*        )}*/}
                {/*    </S.KindOfMenu>*/}
                {/*</li>*/}
                
                {/*<li>*/}
                {/*    <S.KindOfMenu onMouseOver={() => setShowSecondMenu(true)}*/}
                {/*                  onMouseLeave={() => setShowSecondMenu(false)}>*/}
                
                {/*        <S.SecondMenu*/}
                {/*            onMouseOver={() => setShowSecondMenu(true)}*/}
                {/*            onMouseLeave={() => setShowSecondMenu(false)}*/}
                {/*            activate={showSecondMenu}*/}
                {/*        >*/}
                {/*            <Link to='/mypage/insuranceadmin' title='moveToInsuranceAdmin'>*/}
                {/*                보험관리*/}
                {/*            </Link>*/}
                {/*        </S.SecondMenu>*/}
                
                {/*        {showSecondMenu && (*/}
                {/*            <li>*/}
                {/*                <S.SecondMenuBox*/}
                {/*                    onMouseLeave={() => {*/}
                {/*                        setShowSecondMenu(false)*/}
                {/*                    }}*/}
                {/*                    initial='exit'*/}
                {/*                    animate={showSecondMenu ? 'enter' : 'exit'}*/}
                {/*                    variants={menuAnimate}*/}
                {/*                >*/}
                {/*                    {navMenu["second"].map((menu, i) => {*/}
                {/*                        return (*/}
                {/*                            <S.SecondSubMenu key={i}*/}
                {/*                                             onClick={() => setShowSecondMenu(false)}>*/}
                {/*                                <Link to={menu[Object.keys(menu)]}*/}
                {/*                                   title={`moveToMenu${i}`}>*/}
                {/*                                    {Object.keys(menu)}*/}
                {/*                                </Link>*/}
                {/*                            </S.SecondSubMenu>*/}
                {/*                        );*/}
                {/*                    })}*/}
                {/*                </S.SecondMenuBox>*/}
                {/*            </li>*/}
                {/*        )}*/}
                {/*    </S.KindOfMenu>*/}
                {/*</li>*/}

                <li onKeyUp={(e)=>{
                    if (e.key === "Tab") {
                        e.preventDefault();
                        setShowFirstMenu(false)
                        setShowSecondMenu(false)
                    }
                }}>
                    <S.KindOfMenu>

                        <S.ThirdMenu margin={numberOfMessage !== 0}>
                            <Link to={'/useddeal?menu=goodslist'}>
                                중고거래
                            </Link>
                        </S.ThirdMenu>

                    </S.KindOfMenu>
                </li>
                {numberOfMessage !== 0 &&
                    <S.MessageAlarm>
                        <S.Numbering>{numberOfMessage}+</S.Numbering>
                        <S.ToolTip>안 읽은 쪽지가 <span>{numberOfMessage}</span>건 있습니다.</S.ToolTip>
                    </S.MessageAlarm>
                }
                <li onKeyUp={(e)=>{
                    if (e.key === "Tab") {
                        e.preventDefault();
                        setShowFirstMenu(false)
                        setShowSecondMenu(false)
                    }
                }}>
                    <S.KindOfMenu>

                        <S.FourthMenu>
                            <Link to={'/aboutus'}>
                                회사소개
                            </Link>
                        </S.FourthMenu>
                    </S.KindOfMenu>
                </li>
                <li onKeyUp={(e)=>{
                    if (e.key === "Tab") {
                        e.preventDefault();
                        setShowFirstMenu(false)
                        setShowSecondMenu(false)
                        setShowFifthMenu(true)
                    }
                }}>
                    <S.KindOfMenu onMouseOver={() => setShowFifthMenu(true)}
                                  onMouseLeave={() => setShowFifthMenu(false)}>

                        <S.FifthMenu
                            onMouseOver={() => setShowFifthMenu(true)}
                            onMouseLeave={() => setShowFifthMenu(false)}
                            activate={showFifthMenu}
                        >
                            <Link to={'/notice'}>
                                고객센터
                            </Link>
                        </S.FifthMenu>

                        {showFifthMenu && (
                            <li>
                                <S.FifthMenuBox onMouseLeave={() => {
                                    setShowFifthMenu(false)
                                }}
                                                initial='exit'
                                                animate={showFifthMenu ? 'enter' : 'exit'}
                                                variants={menuAnimate}
                                >
                                    {navMenu["fifth"].map((menu, i) => {
                                        return (
                                            <S.FifthSubMenu key={i}
                                                            onClick={() => {
                                                                setShowFifthMenu(false);
                                                            }}>
                                                <Link to={menu[Object.keys(menu)]}
                                                      title={`${Object.keys(menu)} 페이지로 이동`}>
                                                    {Object.keys(menu)}
                                                </Link>
                                            </S.FifthSubMenu>
                                        );
                                    })}
                                </S.FifthMenuBox>
                            </li>
                        )}
                    </S.KindOfMenu>
                </li>
                {/*<li onKeyUp={(e)=>{*/}
                {/*    if (e.key === "Tab") {*/}
                {/*        e.preventDefault();*/}
                {/*        setShowFirstMenu(false)*/}
                {/*        setShowSecondMenu(false)*/}
                {/*        setShowFifthMenu(false)*/}
                {/*    }*/}
                {/*}}>*/}
                {/*    <S.KindOfMenu>*/}
                {/*        <S.SixthMenu>*/}
                {/*            <Link to={'/useddeal?menu=messagebox'} title='쪽지함 페이지로 이동'>*/}
                {/*                쪽지함*/}
                {/*            </Link>*/}
                {/*        </S.SixthMenu>*/}
                {/*    </S.KindOfMenu>*/}
                {/*</li>*/}
                {/*{numberOfMessage !== 0 &&*/}
                {/*    <S.MessageAlarm>*/}
                {/*        <S.Numbering>{numberOfMessage}+</S.Numbering>*/}
                {/*        <S.ToolTip>안 읽은 쪽지가 <span>{numberOfMessage}</span>건 있습니다.</S.ToolTip>*/}
                {/*    </S.MessageAlarm>*/}
                {/*}*/}
            </S.MenuContainer>
        </S.LeftBox>
    );
}

export default Left;
