import styled from 'styled-components';
import {ReactComponent as Menu} from '../../Svg/menu.svg'
import {ReactComponent as Home} from '../../Svg/home.svg'
import {ReactComponent as UsedDeal} from '../../Svg/transaction2.svg'
import {ReactComponent as MyPage} from '../../Svg/my_page.svg'


export const Wrapper = styled.nav`
  display:none;
  @media screen and (max-width: 480px){
    display:${props => props.$display ? 'none' : 'block'};
    width: 100%;
    height: 7.2rem;
    border-top: 1px solid #EAEAEA;
    background: white;
    position:fixed;
    bottom:0;
    left: 0; 
    padding: 0.8rem 4rem 0 4rem;
    z-index: 995;
    min-width: 360px;
  }

`
export const Icons = styled.div`

    @media screen and (max-width: 480px){
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
`
export const Icon1 = styled(Menu)`

`

export const Icon2 = styled(Home)`

 
    path{
      :nth-child(1) {
        fill : ${props => props.$activate ? ({theme}) => theme.colors.main : ''};

        stroke: ${props => props.$activate ? ({theme}) => theme.colors.main : ''};
      }
      
      :nth-child(2){
        fill : ${props => props.$activate ? ({theme}) => theme.colors.main : ''};

      }
  

    }
`

export const Icon3 = styled(UsedDeal)`


  path{
    :nth-child(1) {
      fill : ${props => props.$activate ? ({theme}) => theme.colors.main : ''};
       stroke: ${props => props.$activate ? 'white' : ''};
    }

    :nth-child(2){
      fill : ${props => props.$activate ? 'white' : ''};

    }
    :nth-child(3){
      fill:  ${props => props.$activate ? ({theme}) => theme.colors.main : ''};


    }
  }
`

export const Icon4 = styled(MyPage)`

  circle{
    fill : ${props => props.$activate ? ({theme}) => theme.colors.main : ''};
    stroke: ${props => props.$activate ? ({theme}) => theme.colors.main : ''};

  }
  
    path{
      fill : ${props => props.$activate ? ({theme}) => theme.colors.main : ''};
      :nth-child(3){
        fill:  ${props => props.$activate ? ({theme}) => theme.colors.main : ''};


      }
      :nth-child(1){
        stroke:  ${props => props.$activate ? ({theme}) => theme.colors.main : ''};


      }
    }
`