import {css} from 'styled-components';

const theme = {
    colors: {
        gray: '#F7F7F7',
        gray2: '#EAEAEA',
        gray3: `#D0D0D0`,
        gray4: `#ACACAC`,
        gray5: `#8B8B8B`,
        gray6: `#454545`,
        gray7: `#313131`,
        black: `#202020`,
        error: `#E70000`,
        main: `#008554`,
        blue: `#0068E2`,
        white: `#FFFFFF`,
        background: `#F9F9F9`,
    },
    fontStyle: {
        mainText: css`
          font-size: 1.6rem;
          line-height: 2rem;
        `,
        header: css`
          font-size: 2rem;
          line-height: 2.4rem;
        `,
        subTitle: css`
          font-size: 1.6rem;
          line-height: 2.2rem;
        `,
        title: css`
          font-size: 1.8rem;
        `,
        error: css`
          font-size: 1.4rem;
          line-height: 2rem;
        `,
        complete: css`
          font-size: 1.4rem;
          line-height: 2rem;
        `,
    },
    button: {
        type1: css`
          background-color: #008554;
          border: #008554 0.3rem solid;
          color: #FFFFFF;
        `,
        type2: css`
          background: #D4E0AB;
          border: #D4E0AB 0.3rem solid;
          color: #FFFFFF;
        `,
        type3: css`
          background: #FFFFFF;
          border: #008554 1px solid;
          color: #008554;
        `,
        type4: css`
          background: #FFFFFF;
          border: #FFFFFF 0.3rem solid;
          color: #008554;
        `,
        type5: css`
          background: #8B8B8B;
          color: #fff;
        `,
        type6: css`
          background: #73a7b7;
          border: #73a7b7 0.05rem solid;
          color: #fff;
        `,
        type7: css`
          background: #FFFFFF;
          border: #757575 1px solid;
          color: #757575;
        `,
        type8: css`
          background: #8B8B8B;
          color: #FFFFFF;
        `
    },

    input: {
        type1: css`
          border: #EAEAEA 1px solid;

          &::placeholder {
            color: #8B8B8B;
          }
        ;
        `,
        type2: css`
          border: #008554 0.15rem solid;

          &::placeholder {
            color: #BBBBBB;
          }
        `,
        type3: css`
          border: #FF0000 1px solid;
        `,
        type4: css`
            background: #F7F7F7;
        `
    },

    allOfCenter: css`
      display: flex;
      justify-content: center;
      align-items: center;
    `,

    check: css`
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
      border: #bbb 0.1rem solid;
      border-radius: 0.2rem;
      cursor: pointer;

      :checked {
        display: inline-block;
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        border: #008554 0.1rem solid;
        border-radius: 0.2rem;
      }

      :checked::after {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 1.5rem;
        height: 1.5rem;
        content: "✔";
        text-align: center;
        border-radius: 0.2rem;
        background: #008554;
        color: white;
        font-size: 1rem;
      }
    `,

    radio: css`
      label {
        width: 100%;
        height: 4rem;
        display: flex;
        align-items: center;
        padding: 0 2rem;
        cursor: pointer;
        font-size: 1.8rem;

        //:hover {
        //  background-color: #D4E0AB;
        //}
      }

      input[type='radio'] {
        position: relative;
        vertical-align: middle;
        appearance: none;
        border: solid 0.1rem #bbb;
        background-color: #fff;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 1.5rem;

        :checked {
          ::after {
            position: absolute;
            content: '';
            width: 1.2rem;
            height: 1.2rem;
            top: 17%;
            left: 17%;
            background-color: #008554;
            border-radius: 50%;
          }
        }
      }
    `

};


export default theme;