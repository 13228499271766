import styled from "styled-components";

function SkipNav(props) {
    return (
            <Wrapper id={'skip'} href='#content'>본문 바로가기</Wrapper>
    );
}

export default SkipNav;

const Wrapper = styled.a`
  display: block;
    position:absolute;
    top:-99px;
    //top:0;
    left:0;
    background:#333;
    color:#fff;
    width:20%;
    padding:10px 0;
    text-align:center;
    text-decoration:none;
    z-index:999;
    font-size:2.4rem;
    font-weight:bold; 

    :focus {
      display:block;
      top:8px;
      left: 8px;

      outline:4px solid blue !important;
    }
  
`