import {useState} from 'react';
import {useEffect} from "react";
import {requestGet} from "../../services/request";
import * as S from './style/SideBarStyle'
import {sideBarData} from "../../models/sideBarData";
import Modal from "../Modal/Modal";
import {useNavigate} from "react-router";
import {getCookie} from "../../services/loginCookie";

const SideBar = ({handleSideBar}) => {
    const [isModal, setIsModal] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [showSubMenu, setShowSubMenu] = useState();

    const navigate = useNavigate()


    const handleModal = () => {
        setIsModal(!isModal);
    }


    useEffect(() => {
        document.getElementById('sidebar').style.cssText = `
        position: fixed;
        top: 0;`;
        document.body.style.cssText = `
        position: fixed;
        top: -${window.scrollY * 0.1}rem;
        overflow-y: scroll;
        width: 100%;`;
        return () => {
            document.body.style.cssText = '';
            window.scrollTo(0, 0);
        };
    }, []);


    const handleMovePage = (pathName) => {
        handleSideBar();
        navigate(pathName);
    }

    const [userData, setUserData] = useState();


    const getUserInfo = async () => {
        const res = await requestGet(`/myPage`)
        if (res.code === 0) {
            setUserData(res?.data?.userInfo)
        }
    }

    useEffect(() => {
        try {
            getUserInfo();
        } catch (e) {
            console.log(e)
        }
    }, [getCookie('wid_ys')])


    return (
        <>
            {isModal &&
                <Modal title='로그아웃 하시겠습니까?'
                       type='logout'
                       handleModal={handleModal}
                />
            }
            <S.Wrapper id='sidebar'
                       onClick={(e) => {
                           handleSideBar()
                       }}>
            </S.Wrapper>

            <S.Container
                initial={{x: -500}}
                animate={{x: 0}}
                transition={{ease: "easeOut", duration: 0.3}}>
                <S.Box>
                    <S.XBtn>
                        <S.XIcon onClick={handleSideBar}
                                 role={'button'}
                                 aria-label={'사이드바 닫기'}
                                 tabIndex={0}
                                 onChange={e=>{
                                     if(e.key === 'Enter'){
                                         handleSideBar()
                                     }
                                 }}
                        />
                    </S.XBtn>
                    {(getCookie('wid_ys') !== 0 || (userData == undefined || userData == null || userData == ''))
                        ? <S.Title>로그인이 필요합니다.</S.Title>
                        : <S.Title2>
                            <h1>{userData?.name}</h1>
                            <h1>님 안녕하세요</h1>
                        </S.Title2>
                    }
                    <S.ButtonBox>


                        {(getCookie('wid_ys') !== 0 || (userData == undefined || userData == null || userData == ''))
                            ?
                            <>
                                <S.SignUpBtn
                                    title={'회원가입'}
                                    onClick={() => {
                                    handleMovePage('/signup')

                                }}>회원가입</S.SignUpBtn>
                                <S.LoginBtn onClick={() => handleMovePage('/login')}
                                title={'로그인'}
                                >로그인</S.LoginBtn>
                            </>
                            :
                            <S.LogoutBtn
                                onClick={() => {
                                    setIsModal(true);
                                    // handleLogout();
                                }}
                            title={'로그아웃'}
                            >로그아웃</S.LogoutBtn>
                        }

                    </S.ButtonBox>
                </S.Box>
                <S.DivisionLine/>
                <S.Box2>
                    {sideBarData?.map((data, i) => {
                        return (
                            <S.MainMenuBox key={data + i}>
                                <S.MainMenu onClick={() => {
                                    if (data?.id === 3 || data?.id === 4) {
                                        navigate(data.href);
                                        // window.location.href  = data.href;
                                        handleSideBar()
                                    } else {
                                        if (toggle && data.id != showSubMenu) {
                                            setToggle(true);
                                            setShowSubMenu(data.id);

                                        } else {
                                            setToggle(!toggle);
                                            setShowSubMenu(data.id);
                                        }
                                    }

                                }}>
                                    {data.name}

                                    {data.subMenu && <S.DropDownIcon $rotate={toggle && (data.id == showSubMenu)}/>}

                                </S.MainMenu>
                                {(toggle && showSubMenu == data.id) &&
                                    <>
                                        <S.SubMenuBox
                                            initial={{opacity: 0, height: 0}}
                                            animate={{opacity: 1, height: '100%'}}
                                            transition={{duration: 0.5}}>
                                            {(data.subMenu)?.map((sub, i) => {
                                                return (
                                                    <S.SubMenu key={sub + i}
                                                               onClick={() => {
                                                                   // window.location.href  = sub.href;
                                                                   navigate(sub.href);
                                                                   handleSideBar()
                                                               }}>
                                                        {sub.name}
                                                    </S.SubMenu>
                                                )
                                            })}
                                        </S.SubMenuBox>
                                    </>
                                }
                            </S.MainMenuBox>
                        )
                    })}

                </S.Box2>
            </S.Container>

        </>
    );
};

export default SideBar;
