export const sideBarData = [
    {
        id: 1,
        name: '휠체어보험',
        href: '/',
        subMenu: [
            {
                id: 1,
                name: '보험상품',
                href: '/insurancedetail/30'
            },
            {
                id: 2,
                name: '보험 갱신하기',
                href: '/mypage/insuranceadmin'
            },
            {
                id: 3,
                name: '보험금 청구하기',
                href: '/mypage/insuranceadmin'
            },
            {
                id: 4,
                name: '변경/해지',
                href: '/mypage/insuranceadmin'
            },
            {
                id: 5,
                name: '보험내역',
                href: '/mypage/insuranceadmin'
            },
            {
                id: 6,
                name: '보험금 지급사례',
                href: '/paymentcase'
            },
        ]
    },
    // {
    //     id: 2,
    //     name: '여행자보험',
    //     href: '/',
    //     subMenu: [
    //         {
    //             id: 1,
    //             name: '국내 여행자보험',
    //             href: '/signuptravelinsurance?region=domestic&menu=signup'
    //         },
    //         {
    //             id: 2,
    //             name: '해외 여행자보험',
    //             href: '/signuptravelinsurance?region=global&menu=signup'
    //         },
    //         {
    //             id: 3,
    //             name: '여행자보험 Q&A',
    //             href: '/travelfaq'
    //         },
    //         {
    //             id: 4,
    //             name: '여행자보험 공지',
    //             href: '/travelnotice'
    //         },
    //     ]
    // },
    {
        id: 3,
        name: '중고거래',
        href: '/useddeal?menu=goodslist',
    },
    {
        id:4,
        name: '회사소개',
        href: '/aboutus',
    },
    {
        id: 5,
        name: '고객센터',
        href: '/',
        subMenu: [
            {
                id: 1,
                name: '공지사항',
                href: '/notice'
            },
            {
                id: 2,
                name: '자주하는 질문',
                href: '/faq'
            },
            {
                id: 3,
                name: '문의하기',
                href: '/mypage/inquiryadmin?menu=write'
            },
        ]
    }
]