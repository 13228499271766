import styled from "styled-components";
import {useEffect, useState} from "react";
import {requestGet} from "../../services/request.js";
import Loading from "../../components/Loading.jsx";
import Modal from '../../components/Modal/Modal'
import afterLoginMoveLocate from "./LoginUtil";


const SignHandler = () => {


    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState('')

    const url = new URL(window.location.href)
    const authorizationCode = url.searchParams.get('code')

    const [isModal, setIsModal] = useState(false);

    const handleModal = () => {
        setIsModal(!isModal);
    }

    const getUser = async () => {

        const auth = await requestGet(`/login/kakao/token?code=${authorizationCode}`);

        if (auth.code === 0) {

            const res = await requestGet(`/login/kakao/signInHandler?token=${auth?.data?.access_token}`)

            if (res.code === 0) {
                setLoading(false);
                afterLoginMoveLocate(res.data.blackList);
            }

        }
    }

    useEffect(() => {
        setLoading(true);
        getUser();
    }, [])




    return (
        <>
            {isModal && <Modal handleModal={handleModal}
                               type='overlap'
                               title={modalMessage} />
            }
            <Wrapper>
                {loading ? <Loading/> : ''}
            </Wrapper>
        </>
    );
};

export default SignHandler;


const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`