import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useNavigate, useLocation} from "react-router";
import {getCookie} from "./loginCookie";


export const PrivateRoute = ({authenticated}) => {
    const location = useLocation();

    const isAuthenticated = getCookie('wid_ys') === 0;

    if (authenticated) {
        return isAuthenticated ? <Outlet/> :
            <Navigate to="/login" state={location} />
    }else{
        return <Outlet />
    }
    // 로그인안하고 보험가입페이지까지 가서 이전버튼 누를때 값 넘겨주기 위해서 location 데이터 전체를 담아 보내야해서 넣었음.

}


export default PrivateRoute;