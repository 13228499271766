import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const global = createGlobalStyle`
  ${reset};


  * {
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Pretendard','Apple SD Gothic Neo', 'sans-serif' !important;
    margin:0;
    padding: 0;
    
  }

  html{
    font-size:62.5%;
  }
  
 body{
    overflow-x: hidden;
    font-family: 'Pretendard', 'sans-serif';
   min-width: 1080px;

   @media screen and (max-width: 480px){
     min-width: 360px;

   }
  }

#root{
  display:flex;
  flex-direction:column;
  min-height: 100vh;
  
  @media screen and (max-width: 480px){
    overflow-x: hidden;

  }
}

  input, textarea, button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
  }

  input[type='radio']{
    appearance: auto;
  }


  input,
  button{
    border:none;
    border-radius:0.8rem;
    width: 100%;
  }

  button {
    background: transparent;
    cursor:pointer;
    text-align: center;
  }


  button:focus,
  button:active,
  a:focus,
  a:active,
  input:focus, textarea:focus, li:focus,  label:focus
  {
    outline: 2.5px solid #015ECC;
    outline-offset: 3px;

    :focus-visible{
      outline: 2.5px solid #015ECC;
      outline-offset: 3px;

      @media screen and (max-width: 480px){
        outline:none;
      }
    }
  }


  //input:focus, textarea:focus, li:focus,  label:focus{
  //  //outline:none;
  //  //box-shadow: 4px 4px 16px 0px rgba(149, 177, 52, 0.12);
  //  //border: 2px solid #028554 !important;
  //  //border: 1px solid #008554 !important;
  //  outline: 1.5px solid #015ECC;
  //  outline-offset: 3px;
  //  
  //  @media screen and (max-width: 480px){
  //    outline:none;
  //  }
  //}
  


  img{
    -webkit-user-drag: none;
  }
  
`;


export default global;
