import styled from 'styled-components'
import {ReactComponent as WheelIcon} from "../Svg/shortcuts_wheel.svg";
import {ReactComponent as DomesticIcon} from "../Svg/shortcuts_domestic.svg";
import {ReactComponent as GlobalIcon} from "../Svg/shortcuts_global.svg";
import {ReactComponent as UsedDeal} from "../Svg/shopping.svg";

export const Wrapper = styled.aside`
  position: fixed;
  background: white;
  width: 12rem;
  top: 30vh;
  right: 0;
  border-radius: 1.2rem;
  border: 1px solid ${({theme}) => theme.colors.main};
  box-shadow: 4px 4px 16px 0px rgba(149, 177, 52, 0.12);
  z-index: 100;
  @media screen and (max-width: 480px) {
    display: none;
  }
`

export const Container = styled.div`
  padding: 0 1.3rem;

  > div {
    font-size: 1.6rem;
    line-height: 2rem;
    white-space: pre-wrap;
    text-align: center;
    padding: 2rem 0;
    cursor: pointer;
    border-top: 1px solid ${({theme}) => theme.colors.gray2};
    border-bottom: 1px solid ${({theme}) => theme.colors.gray2};

    :last-child {
      border-bottom: none;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: black;
    }
  }

  picture {
    img {
      width: 4rem;
      height: 4rem;
    }
  }
`

export const Wheel = styled(WheelIcon)`
  width: 4rem;
  height: 4rem;
  margin-bottom: 0.4rem;
`

export const Domestic = styled(DomesticIcon)`
  width: 4rem;
  height: 4rem;
  margin-bottom: 0.4rem;

`

export const Global = styled(GlobalIcon)`
  width: 4rem;
  height: 4rem;
  margin-bottom: 0.4rem;

`

export const UsedDealIcon = styled(UsedDeal)`
  width: 4rem;
  height: 4rem;
  margin-bottom: 0.4rem;

`