import * as s from './ShortcutsMenuStyle';
import {Link} from "react-router-dom";

function ShortcutsMenu({top,right}) {

    const shortcutsMenuData = [
        {
            id:1,
            name:'휠체어보험\n가입하기',
            alt:'휠체어보험 가입하기 페이지로 이동',
            href:'/insurancedetail/30',
            png:'https://kr.object.fin-ncloudstorage.com/wheelchairkorea/ver2/etc/wheelchair.png',
            webp:'https://kr.object.fin-ncloudstorage.com/wheelchairkorea/ver2/etc/wheelchair.webp',
        },
        // {
        //     id:2,
        //     name:'국내 여행보험\n가입하기',
        //     alt:'국내 여행자보험 가입하기 페이지로 이동',
        //
        //     href:'/signuptravelinsurance?region=domestic&menu=signup',
        //     png:'https://kr.object.fin-ncloudstorage.com/wheelchairkorea/ver2/etc/carrier.png',
        //     webp:'https://kr.object.fin-ncloudstorage.com/wheelchairkorea/ver2/etc/carrier.webp',
        // },
        // {
        //     id:3,
        //     name:'해외 여행보험\n가입하기',
        //     alt:'해외 여행자보험 가입하기 페이지로 이동',
        //     href:'/signuptravelinsurance?region=global&menu=signup',
        //     png:'https://kr.object.fin-ncloudstorage.com/wheelchairkorea/ver2/etc/airplane.png',
        //     webp:'https://kr.object.fin-ncloudstorage.com/wheelchairkorea/ver2/etc/airplane.webp',
        // },
        {
            id:4,
            name:'중고거래\n보러가기',
            alt:'중고거래 페이지로 이동',
            href:'/useddeal?menu=goodslist',
            png:'https://kr.object.fin-ncloudstorage.com/wheelchairkorea/ver2/etc/shopping.png',
            webp:'https://kr.object.fin-ncloudstorage.com/wheelchairkorea/ver2/etc/shopping.webp',
        },    {
            id:5,
            name:'고객센터\nFAQ',
            alt:'자주묻는 질문으로 이동',
            href:'/faq',
            png:'https://kr.object.fin-ncloudstorage.com/wheelchairkorea/a11y/etc/call.png',
            webp:'https://kr.object.fin-ncloudstorage.com/wheelchairkorea/a11y/etc/call.webp',
        },
    ]


    return (
        <s.Wrapper top={top} right={right}>
            <s.Container>
                {shortcutsMenuData.map((data,i)=>{
                    return(
                        <div key={data+i}>
                            <Link to={data.href} title={data.alt}>
                                    <picture>
                                    <source srcSet={data.webp} type={'image/webp'}/>
                                        <img src={data.png} alt={''}/>
                                    </picture>
                                {data.name}
                            </Link>
                        </div>
                    )
                })}

            </s.Container>
        </s.Wrapper>
    );
}

export default ShortcutsMenu;