import axios from 'axios'


const client = axios.create({
    headers:{
        'Access-Control-Allow-Origin':`${process.env.REACT_APP_BASE_URL}`,
        'Access-Control-Allow-Credentials':"true"
    },
    withCredentials: true,
    baseURL:`${process.env.REACT_APP_BASE_URL}`
})



// axios.interceptors.response.use(
//     function (response) {
//         // 응답 데이터를 가공
//         // ...
//         console.log(response)
//
//         return response;
//     },
//     function (error) {
//         // 오류 응답을 처리
//         // ...
//
//         console.log(error)
//         console.log(Promise.reject(error))
//
//         return Promise.reject(error);
//     });


// axios.interceptors.request.use(
//     function (config) {
//         // 응답 데이터를 가공
//         // ...
//         console.log(config)
//
//         return config;
//     },
//     function (error) {
//         // 오류 응답을 처리
//         // ...
//         console.log(error)
//
//         return Promise.reject(error);
//     });

// const token = localStorage.getItem('accessToken') ?  localStorage.getItem('accessToken') : sessionStorage.getItem('accessToken');
// client.defaults.headers.common['x-auth-token'] = token ? token : null



export {client};
