import styled from 'styled-components'
import {ReactComponent as Call} from "../../Svg/call.svg";
import {Link} from "react-router-dom";


export const Wrapper = styled.footer`
  display:${props => props.$display ? 'none' : 'block'};
  width: 100%;
   height: auto;
  margin-top: auto;
  z-index: 99;
  background: ${({theme}) => theme.colors.gray};
  
  //보험상세일때
  //margin-bottom: 12.2rem;

  @media screen and (max-width: 480px) {
    margin-bottom: 7.2rem;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${props => props.$paddingUp ? '3.2rem 0 18.2rem 0' : '3.2rem 0 6.2rem 0'};
  
  @media screen and (max-width: 480px) {
    padding: ${props => props.$paddingUp ? '3.2rem 1.6rem 10.2rem 1.6rem' : '2rem 1.6rem 3.4rem 1.6rem'};

  }
`

export const ArsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 7.2rem;
  border-top: 1px solid ${({theme}) => theme.colors.gray2};
  border-bottom: 1px solid ${({theme}) => theme.colors.gray2};
  background: white;
`

export const Ars = styled.div`
  display: flex;
  align-items: center;
  width: 76.8rem;
  z-index: 99;

  color: ${({theme}) => theme.colors.gray6};
  @media screen and (max-width: 480px) {
    display: block;
    padding: 1.6rem 2.4rem;
    border-top: 1px solid ${({theme}) => theme.colors.gray2};
    border-bottom: 1px solid ${({theme}) => theme.colors.gray2};
    background: white;
  }



`

export const ArsNumber = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 0.8rem;


  > p {
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: ${({theme}) => theme.colors.gray7};

    :nth-child(3) {
      font-weight: bold;
    }
  }

  @media screen and (max-width: 480px) {
    margin-bottom: 0.6rem;

    > p {
      font-size: 1.4rem;
      color: ${({theme}) => theme.colors.gray6};
    }
  }

`

export const ArsNumber2 = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.8rem;


  > p {
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: ${({theme}) => theme.colors.gray7};

    :nth-child(3) {
      font-weight: bold;
    }
  }

  @media screen and (max-width: 480px) {
    margin-bottom: 0.6rem;

    > p {
      font-size: 1.4rem;
      color: ${({theme}) => theme.colors.gray6};
    }
  }

`

export const CallIcon = styled(Call)`
  width: 1.8rem;
  height: 1.8rem;
  margin: 0 0.2rem 0 0.8rem;

`

export const ArsHour = styled.p`
  font-size: 1.6rem;
  line-height: 1.8rem;
  color:${({theme})=>theme.colors.gray6};

  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
`


export const Contents = styled.div`
  width: 76.8rem;
  position:relative;
  
  > a{
    position:absolute;
    right: -12rem;
    top: 1rem;
    display: block;
    
    img{
      width: 12rem;
      height: auto;
    }
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    display: block;
    
    > a{
      position:absolute;
      right: 0;
      top: 71%;
      display: block;

      img{
        width: 7.2rem;
        height: auto;
      }
    }
    
  }

`

export const Content = styled.div`
  width: 108rem;

`

export const PolicyAndIntroduce = styled.div`
  width: 100%;
  margin-bottom: 0.6rem;

  a {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({theme}) => theme.colors.gray7};

    :first-child {
      padding-right: 1rem;
      margin-right: 1rem;
      border-right: 1px solid ${({theme}) => theme.colors.gray2};

    }

  }

  @media screen and (max-width: 480px) {

    a {
      font-size: 1.2rem;
      line-height: 1.8rem;

      :last-child {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }


`
export const CompanyDescription = styled.p`
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({theme}) => theme.colors.gray5};

  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
    line-height: 2rem;
  }
`