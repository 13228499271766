import {client} from "../../services/client";
import {setCookie} from "../../services/loginCookie";


export default function afterLoginMoveLocate(blackList) {
    const href = localStorage.getItem("location");
    localStorage.removeItem('location');
    localStorage.removeItem('state');

    setCookie('wid_ys',0,{
        path:'/',
        // maxAge: 60 * 10

        // 아래는 라이브서버에서
        maxAge: 60 * 60 * 24
    })

    sessionStorage.setItem('whether', blackList);
    sessionStorage.setItem('autoLogin', 'n');


    if (href === null || typeof href === 'undefined' || href === '' || href === '/') {
        window.location.href = '/';
    } else {
        window.location.href = href;
    }
}

