import styled from "styled-components";
import {ReactComponent as Logo} from "../../Svg/logo.svg";
import {ReactComponent as Message} from "../../Svg/message.svg";
import {motion} from "framer-motion";
import {Link} from "react-router-dom";


export const Wrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 995;
  width: 100%;
  height: 9rem;
  border-bottom: 1px solid #F7F7F7;
  background: white;

  @media screen and (max-width: 480px) {
    display: block;
    height: 5.6rem;
    min-width: 360px;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 108rem;
min-width: 1080px;
  
`

export const Icons = styled.div`
  @media screen and (max-width: 480px) {
    position: relative;
    width: 100%;
    height: 5.6rem;

  }
`

export const LogoIcon = styled(Logo)`
  width: 17.2rem;
  height: 4.8rem;

  @media screen and (max-width: 480px) {
    position: absolute;
    top: 0.5rem;
    left: 1.2rem;
    cursor: pointer;
    width: 15rem;

  }

`

export const MessageAlarm = styled.div`
  position: absolute;
  top: 35%;
  right: 46%;

  @media screen and (max-width: 480px) {
    top: 1.8rem;
    right: 1.6rem;
    width: 2.4rem;
    height: 2.4rem;
  }

`

export const MessageIcon = styled(Message)`
  
  @media screen and (max-width: 480px) {
    width: 2.4rem;
    height: 2.4rem;
  }

`

export const Numbering = styled.button`
  bottom: 54%;
  right: -61%;
  width: 4.2rem;
  height: 2.8rem;
  ${({theme}) => theme.button.type1};
  font-size: 1.4rem;
  line-height: 1.8rem;
  border-radius: 1.8rem;
  
  @media screen and (max-width: 480px) {
    position: absolute;
    bottom: 54%;
    right: -61%;
    width: 2.8rem;
    height: 1.6rem;
    ${({theme}) => theme.button.type1};
    font-size: 1rem;
    line-height: 1.2rem;
  }

`

export const ToolTip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -5.7rem;
  right: 4.5rem;
  width: 23.4rem;
  height: 4.2rem;
  border: 1px solid ${({theme}) => theme.colors.gray2};
  border-radius: 0.8rem;
  background: white;
  font-size: 1.6rem;

  span {
    margin-left: 0.3rem;
    color: ${({theme}) => theme.colors.main};
  }
  
  ::before {
    display: block;
    position: absolute;
    top: -29%;
    left: 84.5%;
    z-index: 994;
    width: 0px;
    border-style: solid;
    border-width: 0px 4px 11px 5.5px;
    border-color: rgb(234, 234, 234) transparent;
    content: "";
  }

  ::after {
    display: block;
    position: absolute;
    top: -23%;
    left: 84.5%;
    z-index: 994;
    width: 0;
    border-style: solid;
    border-width: 0px 4px 11px 5.5px;
    border-color: #FFFFFF transparent;
    content: '';
  }
  
  @media screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -4.5rem;
    right: -1.5rem;
    width: 16.4rem;
    height: 4.2rem;
    border: 1px solid ${({theme}) => theme.colors.gray2};
    border-radius: 0.8rem;
    background: white;
    font-size: 1.2rem;

    span {
      margin-left: 0.3rem;
      color: ${({theme}) => theme.colors.main};
    }
    
    ::before {
      display: block;
      position: absolute;
      top: -14%;
      left: 81.5%;
      z-index: 994;
      width: 0;
      border-style: solid;
      border-width: 0 3px 5px 3.5px;
      border-color: ${({theme}) => theme.colors.gray2} transparent;
      content: '';
    }

    ::after {
      display: block;
      position: absolute;
      top: -10%;
      left: 81.5%;
      z-index: 994;
      width: 0;
      border-style: solid;
      border-width: 0 3px 5px 3.5px;
      border-color: #FFFFFF transparent;
      content: '';
    }
  }

`



export const LeftBox = styled.nav`
  display: flex;
  align-items: center;
`;

export const MainLink = styled(Link)`
position:relative;
  
  >h1 {
    position:absolute;
    text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  }
`



export const MenuContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 44.7rem;
  // 쪽지함 알림 떴을 때
  //width: 56.9rem;
  margin-left: 5rem;
position:relative;
z-index: 995;
`;


export const KindOfMenu = styled.ul`
  display: flex;
  align-items: center;
  position: relative;
  height: 9rem;
  cursor: pointer;
  :hover{
    color:${({theme})=>theme.colors.main};
  }
`;


export const FirstMenu = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.4rem;
  white-space: pre-wrap;

  a {
    display: block;
    color: ${props => props.activate ? ({theme})=>theme.colors.main : ({theme})=>theme.colors.gray7} ;
    transition: 0.3s all;

    :hover{
      color:${({theme})=>theme.colors.main};
    }
  }

`

export const SecondMenu = styled(FirstMenu)``
export const ThirdMenu = styled(FirstMenu)`
margin-right: ${props => props.margin ? '4rem' : 'auto'};
`
export const FourthMenu = styled(FirstMenu)``
export const FifthMenu = styled(FirstMenu)``
export const SixthMenu = styled(FirstMenu)``


export const FirstSubMenu = styled.li`
  cursor: pointer;
  width: 100%;

  a {
    display: block;
    width: 100%;
    padding: 1.2rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: black;

    :hover {
      background-color: ${({theme}) => theme.colors.gray2};
      border-radius: 0;
    }
  }
`
export const SecondSubMenu = styled(FirstSubMenu)``
export const FifthSubMenu = styled(FirstSubMenu)``

export const FirstMenuBox = styled(motion.ul)`
  position: absolute;
  left: -3.5rem;
  top: 7rem;
  width: 16rem;
  height: auto !important;
  padding: 1.5rem 0;
  text-align: center;
  border-radius: 0.8rem;
  box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.06);
 z-index:995;
  background-color: #fff;
`;

export const SecondMenuBox = styled(FirstMenuBox)`
  left: -3.2rem;

`;

export const FifthMenuBox = styled(FirstMenuBox)`
  left: -4.5rem;
  top: 6.4rem;
  padding: 1.5rem 0;

`;


export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const GoToSignUp = styled(Link)`
  display: flex;
  justify-content: center;
align-items: center;
  width: 11.2rem;
  height: 5.2rem;
  margin-right: 1.5rem;
  ${({theme}) => theme.button.type4};
  border-radius: 8px;
  font-size: 2rem;
  font-weight: 500;
  transition: 0.2s all;

`


export const GoToLogin = styled(GoToSignUp)`
  margin-right: 0;
  ${({theme}) => theme.button.type1};
  transition: 0.2s all;
`

export const LogoutBtn = styled.button`
  width: 11.2rem;
  height: 5.2rem;
  ${({theme}) => theme.button.type4};
  font-size: 2rem;
  font-weight: 500;
  transition: 0.2s all;
  margin-right: 0;
  ${({theme}) => theme.button.type1};
`
