import {atom} from "recoil";
import {recoilPersist} from "recoil-persist";


const {persistAtom} = recoilPersist({
    key:'recoil-persist',
    storage: localStorage,
});


export const joinInsurance = atom({
    key:'joinInsurance',
    default:[],
})


export const signupInsuranceValue = atom({
    key:'signupInsuranceValue',
    default:{
        bname: "",
        sido: "",
        sigungu: "",
        type: "",
        deleted: false,
        email: "",
        mid: "",
        moid:'',
        createdAt:'',
        detailAddress: "",
        deviceAddress: "",
        deviceAddressZipCode: "",
        deviceId: 0,
        deviceType: "",
        fullImagePath: "",
        manufacturer: "",
        modelName: "",
        modelId:'',
        purchaseDate: "",
        serialImagePath: "",
        serialNumber: "",
        tel: "",
        userId: "",
        userName: "",
        productId:'',
    },
});

export const insuranceDetailState = atom({
    key:'insuranceDetailState',
    default: '상품안내'
})



export const claimInsuranceValue = atom({
    key: 'claimInsuranceValue',
    default : {
        accidentAddress: "",
        accidentAddressBname: "",
        accidentAddressSido: "",
        accidentAddressSigungu: "",
        accidentAt: '',
        accidentContents: "",
        accidentDetailAddress: "",
        accidentImages: "",
        accidentZipCode: "",
        agreePrivacyUse: "",
        claimBill: "",
        insuranceJoinId: "",
        name: "",
        serialNumber:"",
        stockNumber: "",
        tel: "",
        victimName: "",
        victimPhone: "",

    },
})



export const deviceTypeState = atom({
    key:'deviceTypeState',
    default: 'all'
})

export const planTypeState = atom({
    key:'planTypeState',
    default: 'ADULT'
})


export const goodsFilterState = atom({
    key:'goodsFilterState',
    default: null
})


export const renewalInsuranceValue = atom({
    key: 'renewalInsuranceValue',
    default : {
        accidentAddress: "",
        accidentAddressBname: "",
        accidentAddressSido: "",
        accidentAddressSigungu: "",
        accidentAt: '',
        accidentContents: "",
        accidentDetailAddress: "",
        accidentImages: "",
        accidentZipCode: "",
        agreePrivacyUse: "",
        claimBill: "",
        insuranceJoinId: "",
        name: "",
        serialNumber:"",
        stockNumber: "",
        tel: "",
        victimName: "",
        victimPhone: "",
        deviceType:'',
    },
})


export const planValue = atom({
    key: 'planValue',
    default : {

    }
})

export const travelInputData = atom({
    key:'travelInputData',
    default:{
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        travelPurpose: '',
        travelCountry: '',
        name: '',
        jumin1: '',
        jumin2: '',
        phone: '',
        email1: '',
        email2: '직접입력',
        email3:'',
    }
})

export const addCompanionList = atom({
    key:'addCompanionList',
    default:[]
})

export const choicePlanValue = atom({
    key:'choicePlanValue',
    default: {}
})

export const forPaymentData = atom({
    key:'forPaymentData',
    default:{}
})

export const finalComfirmation = atom({
    key:'finalConfirmation',
    default:{

    }
})

export const individualCoverageData = atom({
    key:'individualCoverageData',
    default:{}
})

export const tryAddCompanion = atom({
    key:'tryAddCompanion',
    default:true
})


export const faqCurrentMenu = atom({
    key:'faqCurrentMenu',
    default:'공지'
})

export const noticeData = atom({
    key:'noticeData',
    default:[],
    effects_UNSTABLE: [persistAtom],
})